






















































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';

import MenuPayment from '../MenuPayment/MenuPayment.vue';
import FormCreditCart from '../FormCreditCart/FormCreditCart.vue';
import FormPix from '../FormPix/FormPix.vue';
import FormBillet from '../FormBillet/FormBillet.vue';
import FormAddress from '../FormAddress/FormAddress.vue';
import FormDiscount from '../FormDiscount/FormDiscount.vue';

import PaymentService from '@/services/Payment/PaymentService';
import { PaymentPost } from '@/services/Payment/paymentInterface';

import {
  INDEX_OPTION_MENU, METHOD_PAYMENT, METHOD_PAYMENT_TITLE, TYPE_DISCOUNT,
} from '../../constants';

import { Plan, Discount } from '../../interfaces/IShopping';
import {
  AddressForm, BilletForm, CreditCartForm, PixForm, DiscountForm,
} from '../../interfaces/IForms';

import { addDealAndPersonInPipedrive } from '@/share/Util/Pipedrive/index';
import { AddDealAndPersonInPipedrive } from '../../../../services/Pipedrive/IPipedrive';

const VALID_CPF = 11;
const CART_ABANDONMENT = 'Abandono de carrinho';

@Component({
  components: {
    MenuPayment,
    FormCreditCart,
    FormPix,
    FormBillet,
    FormDiscount,
    FormAddress,
    Loading,
    Checkbox,
  },
})
export default class PaymentForm extends Vue {
  @Prop({ required: true }) plan!: Plan;

  private INDEX_OPTION_MENU = INDEX_OPTION_MENU;

  private isLoadingDiscount = false;
  private isLoadingPay = false;

  private isApplyDiscount = false;
  private clickedConfirmPurchase = false;

  private discountUser: Discount | null = null;

  private creditCart: CreditCartForm = {
    card_number: '',
    holder_name: '',
    card_expiration: '',
    card_cvv: '',
    installments: 1,
    cpf: '',
  }

  private billet: BilletForm = {
    cpf: '',
  }

  private pix: PixForm = {
    cpf: '',
  }

  private address: AddressForm = {
    zipcode: '',
    street: '',
    state: '',
    city: '',
    neighborhood: '',
    number: '',
    additional_details: '',
  }

  private deliveryAddress: AddressForm = {
    zipcode: '',
    street: '',
    state: '',
    city: '',
    neighborhood: '',
    number: '',
    additional_details: '',
  }

  private discount: DiscountForm = {
    discount: '',
  }

  public checked = true;

  private PaymentService = new PaymentService();

  async beforeDestroy() {
    await this.triggerPipedrive();
  }

  get finalPrice() {
    return this.$store.getters.finalPrice || 0;
  }

  get methods() {
    return METHOD_PAYMENT_TITLE[this.choiceIndexMenu];
  }

  get isValidateForm() {
    return (INDEX_OPTION_MENU.CREDIT_CART === this.choiceIndexMenu && this.isValidateFormCreditCart)
      || (INDEX_OPTION_MENU.BILLET === this.choiceIndexMenu && this.isValidateFormBillet)
      || (INDEX_OPTION_MENU.PIX === this.choiceIndexMenu && this.isValidateFormPix);
  }

  get isValidateFormCreditCart() {
    return !!(this.creditCart.card_number
      && this.creditCart.holder_name
      && this.creditCart.card_expiration
      && this.creditCart.card_cvv
      && this.creditCart.installments
      && this.creditCart.cpf && this.creditCart.cpf.length === VALID_CPF
    );
  }

  get isValidateFormBillet() {
    return !!this.billet.cpf && this.billet.cpf.length === VALID_CPF;
  }

  get isValidateFormPix() {
    return this.pix.cpf && this.pix.cpf.length === VALID_CPF;
  }

  get isValidateFormAddress() {
    return !!(this.address.zipcode
      && this.address.street
      && this.address.state
      && this.address.city
      && this.address.neighborhood
      && this.address.number);
  }

  get isValidateFormDeliveryAddress() {
    return !!(this.deliveryAddress.zipcode
      && this.deliveryAddress.street
      && this.deliveryAddress.state
      && this.deliveryAddress.city
      && this.deliveryAddress.neighborhood
      && this.deliveryAddress.number) || this.checked;
  }

  get isDisableButtonPayment() {
    return !this.isValidateForm
      || !this.isValidateFormAddress
      || !this.isValidateFormDeliveryAddress
      || this.isLoadingPay;
  }

  get choiceIndexMenu() {
    return this.$store.getters.choiceIndexMenu;
  }

  @Watch('this.$route.params', {
    immediate: true,
    deep: true,
  })
  setDiscountRouter() {
    const discountRouter = this.$route.params?.discount;

    if (!discountRouter) return;

    this.discount.discount = discountRouter;

    this.setDiscountUser();
  }

  async setDiscountUser() {
    try {
      const code = this.discount.discount || '';

      if (this.isApplyDiscount) {
        this.resetDiscount();

        return;
      }

      this.isLoadingDiscount = true;

      const response = await this.PaymentService.getDiscountByCode(code);

      this.discountUser = {
        ...response,
        code,
      };

      this.isApplyDiscount = true;

      this.setDiscountStore(this.discountUser);

      this.$store.dispatch('Toast/setToast', {
        text: 'Cupom aplicado com sucesso.',
        status: 'success',
      });
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Cupom de desconto inválido.',
        status: 'error',
      });

      this.isApplyDiscount = false;

      console.error(error);
    } finally {
      this.isLoadingDiscount = false;
    }
  }

  handleCheck(name: string, checked: boolean, index: number, checkValue: boolean) {
    this.checked = checkValue;
  }

  async triggerPipedrive() {
    if (this.clickedConfirmPurchase) return;

    const { name, email } = this.$store.getters.profile;
    const userID = this.$store.getters.profile.idstudent;

    const data: AddDealAndPersonInPipedrive = {
      subject: CART_ABANDONMENT,
      email,
    };

    if (name) {
      data.name = name;
    } else {
      data.userID = userID;
    }

    await addDealAndPersonInPipedrive(data);
  }

  setDiscountStore(discount: Discount | null) {
    this.$store.commit('setDiscountUser', discount);
  }

  resetDiscount() {
    this.discount.discount = '';
    this.discountUser = null;
    this.isApplyDiscount = false;

    this.setDiscountStore(null);
  }

  async handlePayment() {
    try {
      this.clickedConfirmPurchase = true;

      this.isLoadingPay = true;

      const data: PaymentPost = this.paymentData();

      const response = await this.PaymentService.postPaymentPlan(data);

      if (response.paymentIds.length) {
        this.redirectPayment(response.paymentIds[0]);

        this.clearForms();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao comprar o plano.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingPay = false;
    }
  }

  paymentData() {
    const address = this.checked ? this.address : this.deliveryAddress;

    const defaultData: PaymentPost = {
      planID: Number(this.plan.planId),
      amount: Number(this.finalPrice.toFixed(2)),
      ...(this.discountUser?.code !== '' && { discountCode: this.discountUser?.code }),
      paymentMethodCode: METHOD_PAYMENT[this.choiceIndexMenu],
      installments: 1,
      paymentAddress: {
        ...(this.address.additional_details !== '' && { additional_details: this.address.additional_details }),
        zipcode: this.address.zipcode,
        street: this.address.street,
        number: this.address.number,
        neighborhood: this.address.neighborhood,
        city: this.address.city,
        state: this.address.state,
        country: 'BR',
      },
      deliveryAddress: {
        ...(address.additional_details !== '' && { additional_details: address.additional_details }),
        zipcode: address.zipcode,
        street: address.street,
        number: address.number,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
      },
      paymentProfile: {
        registry_code: this.pix.cpf,
      },
    };

    let data: PaymentPost = defaultData;

    switch (METHOD_PAYMENT[this.choiceIndexMenu]) {
      case TYPE_DISCOUNT.CREDIT_CART:
        data = {
          ...defaultData,
          installments: Number(this.creditCart.installments),
          paymentProfile: {
            ...this.creditCart,
            registry_code: this.creditCart.cpf,
          },
        };

        break;

      case TYPE_DISCOUNT.PIX:
        data = defaultData;
        break;

      case TYPE_DISCOUNT.BILLET:
        data = {
          ...defaultData,
          paymentProfile: {
            registry_code: this.billet.cpf,
          },
        };
        break;

      default:
        break;
    }

    return data;
  }

  redirectPayment(idPurchase: string) {
    const { feature, productId } = this.$route.params;

    let name = '';

    if (METHOD_PAYMENT[this.choiceIndexMenu] === TYPE_DISCOUNT.PIX) name = 'PaymentPix';
    if (METHOD_PAYMENT[this.choiceIndexMenu] === TYPE_DISCOUNT.BILLET) name = 'PaymentBillet';
    if (METHOD_PAYMENT[this.choiceIndexMenu] === TYPE_DISCOUNT.CREDIT_CART) name = 'PaymentThankYou';

    this.$router.push({
      name,
      params: {
        feature,
        productId,
        idPurchase,
      },
    });
  }

  clearForms() {
    this.clearFormCreditCart();
    this.clearFormBillet();
    this.clearFormPix();
    this.clearFormAddress();
    this.clearFormDiscount();
    this.clearFormDeliveryAddress();
  }

  clearFormCreditCart() {
    this.creditCart.card_number = '';
    this.creditCart.holder_name = '';
    this.creditCart.card_expiration = '';
    this.creditCart.card_cvv = '';
    this.creditCart.installments = 1;
    this.creditCart.cpf = '';
  }

  clearFormBillet() {
    this.billet.cpf = '';
  }

  clearFormPix() {
    this.pix.cpf = '';
  }

  clearFormAddress() {
    this.address.zipcode = '';
    this.address.street = '';
    this.address.state = '';
    this.address.city = '';
    this.address.neighborhood = '';
    this.address.number = '';
    this.address.additional_details = '';
  }

  clearFormDeliveryAddress() {
    this.deliveryAddress.zipcode = '';
    this.deliveryAddress.street = '';
    this.deliveryAddress.state = '';
    this.deliveryAddress.city = '';
    this.deliveryAddress.neighborhood = '';
    this.deliveryAddress.number = '';
    this.deliveryAddress.additional_details = '';
  }

  clearFormDiscount() {
    this.discount.discount = '';
  }
}
