const LENGTH_PHONE_CORRECT = 15;

export function validate(value: string | boolean) {
  return typeof value === 'boolean';
}

export function validateEmptyAndLength3(value: string) {
  if (!value) return '*Este campo é obrigatório';

  if (value.length < 3) return '*Este campo precisa de no mínimo 3 caracteres';

  return true;
}

export function validateEmptyAndEmail(value: string) {
  if (!value) return '*Este campo é obrigatório';

  const isValid = /^(([^<>()[\]\\.,;:\s@\\""]+(\.[^<>()[\]\\.,;:\s@\\""]+)*)|(\\"".+\\""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value);

  if (!isValid) return '*Este campo precisa ser um e-mail';

  return true;
}

export function validateEmptyAndPassword(value: string, isLogin = true) {
  if (!value) return '*Este campo é obrigatório';

  if (value.length < 3) return '*Este campo precisa de no mínimo 3 caracteres';

  const isValid = isLogin ? true : /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&+=*])(?=.*\d).{8,30}$/i.test(value);

  if (!isValid) return 'Por favor, digite uma senha válida.';

  return true;
}

export function validateEmptyAndName(value: string) {
  if (!value) return '*Este campo é obrigatório';

  const isValid = /[A-Za-z]* [A-Za-z]*/i.test(value);

  if (!isValid) return 'Insira seu nome completo.';

  return true;
}

export function validateEmptyAndPhone(value: string) {
  if (!value) return '*Este campo é obrigatório';

  if (value.length !== LENGTH_PHONE_CORRECT) return 'Formato inválido de telefone.';

  return true;
}

export function validateEmptyAndCode(value: string) {
  if (!value) return '*Este campo é obrigatório';

  if (value.length !== 6) return '*Este campo precisa ter 6 caracteres';

  return true;
}
