import { render, staticRenderFns } from "./PaymentLoading.vue?vue&type=template&id=e8b28c0e&scoped=true&"
import script from "./PaymentLoading.vue?vue&type=script&lang=ts&"
export * from "./PaymentLoading.vue?vue&type=script&lang=ts&"
import style0 from "./PaymentLoading.scss?vue&type=style&index=0&id=e8b28c0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8b28c0e",
  null
  
)

export default component.exports