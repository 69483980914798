import PipedriveService from '@/services/Pipedrive/Pipedrive';
import { AddDealAndPersonInPipedrive } from '@/services/Pipedrive/IPipedrive';

const pipedriveService = new PipedriveService();

export async function addDealAndPersonInPipedrive(data: AddDealAndPersonInPipedrive) {
  if (!data) return;

  try {
    await pipedriveService.AddDealAndPersonInPipedrive(data);
  } catch (error) {
    console.error(error);
  }
}
