



















































































import {
  Component, Vue, Watch,
} from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';
import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import { LoginForm } from '../../interfaces/IForms';

import AccountService from '@/services/Account/AccountService';
import CookieUtil from '@/services/Util/cookieUtil';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { updateProfile } from '@/share/Util/Profile/Profile';
import {
  validate,
  validateEmptyAndEmail,
  validateEmptyAndPassword,
} from '@/share/Util/Validators';

const MESSAGE = {
  MSG_ERROR_LOGIN:
    'Verifique se o e-mail e a senha estão corretos e tente novamente.',
  MSG_ERROR_SERVER:
    'Infelizmente estamos passando por instabilidades, tente novamente mais tarde.',
  MSG_SUCCESS_LOGIN: 'Login realizado com sucesso',
};

@Component({
  components: {
    Loading,
    FeedbackRequiredField,
  },
})
export default class FormLogin extends Vue {
  private isLoading = false;

  private showCurrentPassword = false;

  private data: LoginForm = {
    email: '',
    password: '',
  };

  private accountService = new AccountService();

  private emailField = { isError: true, message: '' }
  private passwordField = { isError: true, message: '' }

  get isValidateForm() {
    return !this.emailField.isError
      && !this.passwordField.isError;
  }

  @Watch('data.email')
  validateEmail() {
    if (this.data.email !== null) {
      this.emailField = { isError: false, message: '' };
    }
    const isValidateEmail = validate(validateEmptyAndEmail(this.data.email));
    this.emailField = { isError: !isValidateEmail, message: String(validateEmptyAndEmail(this.data.email)) };
  }

  @Watch('data.password')
  validatePassword() {
    if (this.data.password !== null) {
      this.passwordField = { isError: false, message: '' };
    }

    const isValidatePassword = validate(validateEmptyAndPassword(this.data.password));

    this.passwordField = { isError: !isValidatePassword, message: String(validateEmptyAndPassword(this.data.password)) };
  }

  async handleLoginAccount() {
    try {
      this.isLoading = true;

      const response = await this.accountService.login({
        email: this.data.email || '',
        password: this.data.password || '',
      });

      if (response?.status === STATUS_RESPONSE_API.SUCCESS_OK) {
        this.$store.dispatch('Toast/setToast', {
          text: MESSAGE.MSG_SUCCESS_LOGIN,
          status: 'success',
        });

        const token = response?.data?.data?.token;

        await this.setSessionPlatform(token);
      }
    } catch (error) {
      const responseError = error?.response?.data;
      const responseErrorStatus = responseError?.statusCode;

      if (
        responseErrorStatus === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND
        || responseErrorStatus === STATUS_RESPONSE_API.CLIENT_ERROR_UNAUTHORIZED
      ) {
        this.$store.dispatch('Toast/setToast', {
          text: MESSAGE.MSG_ERROR_LOGIN,
          status: 'error',
        });

        return;
      }

      this.$store.dispatch('Toast/setToast', {
        text: MESSAGE.MSG_ERROR_SERVER,
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }

  setTokenStorageAndCookie(token: string) {
    this.$store.commit('setToken', token);

    localStorage.setItem('token', token);

    CookieUtil.setCookie('auth_token', token);
  }

  async setSessionPlatform(token: string) {
    this.setTokenStorageAndCookie(token);

    this.$store.commit('initialState');
    this.$store.commit('setAppStart', true);

    await updateProfile();
  }

  handleRedirectCreateAccount() {
    this.$emit('change-account');
  }
}
