




































import {
  Component, Vue,
} from 'vue-property-decorator';

import FormRegister from '../FormRegister/FormRegister.vue';
import FormLogin from '../FormLogin/FormLogin.vue';

@Component({
  components: {
    FormRegister,
    FormLogin,
  },
})
export default class ContainerAccess extends Vue {
  private isShowLogin = false;

  handleChangeLogin() {
    this.isShowLogin = true;
  }

  handleChangeCreateAccount() {
    this.isShowLogin = false;
  }

}
