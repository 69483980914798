import AppClient from '../AppClient';

import { AddDealAndPersonInPipedrive } from './IPipedrive';

export default class PipedriveService {
  private basePath = process.env.VUE_APP_PAYMENT_API;

  private Client = new AppClient({ url: this.basePath });

  async AddDealAndPersonInPipedrive(data: AddDealAndPersonInPipedrive) {
    const URI = '/pipedrive';

    const response = await this.Client.post(URI, data);

    return response?.data;
  }
}
